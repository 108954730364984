<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-modal
            v-show="!show"
            id="add_customer"
            ref="add_customer"
            :title="$parent.lang.add_customer"
            size="lg"
            :footer-bg-variant="`warning`"
            :header-bg-variant="`dark`"
            :header-text-variant="`light`"
            lazy
        >
            <template #modal-header >
                <div class="w-100 flexit" style="direction:rtl;text-align:center">
                    <div style="margin-bottom:5px!important;font-size:1.3rem;border:2px solid #fff !important;width:150px;display:flex;justify-content: center;justify-items: center;align-items: center;">
                        {{ $parent.lang.add_customer }}
                    </div>
                </div>
            </template>

        <div class="px-3 py-2" style="direction:rtl">
            <div class="tobbuttom backBlack" :style="`direction:${$parent.lang.dir};padding:7px;width:100%;text-align:center;`">
                <b-button :style="`border-radius:0;background:transparent;color:#fff;border:none;`">{{$parent.lang.customer_type}}</b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="cInd" :class="`dispFlex btn btn-small redColor borderRad_`+$parent.lang.algin" @click="changeCusType(0)" >{{$parent.lang.individual_client}} <i id="indiv"  class="fas fa-arrow-down"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="cCom" :class="`dispFlex btn btn-small weightColor borderRad_none`" @click="changeCusType(1)" >{{$parent.lang.company_clinet}} <i id="comps"  class="fas fa-arrow-down hideme"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="cGov" :class="`dispFlex btn btn-small weightColor borderRad_`+$parent.lang.lalgin" @click="changeCusType(2)" >{{$parent.lang.government_clinet}} <i id="goves" class="fas fa-arrow-down hideme"></i></b-button>
            </div>
            <v-row>
                <v-col cols="12" md="2" sm="12">
                    <label>{{$parent.lang.customerid}}</label>
                    <b-form-input
                        class="inborder"
                        :label='$parent.lang.customerid'
                        v-model="customers.customerid"
                        type="number"
                        :rules="mobileRules"
                        >{{ customers.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{$parent.lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label='$parent.lang.mobile'
                        v-model="customers.mobile"
                        type="number"
                        :rules="mobileRules"
                        >{{ customers.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{$parent.lang.additional_mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.additional_mobile"
                        v-model="customers.mobile1"
                        type="number"
                        >{{ customers.mobile1 }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                    <label>{{$parent.lang.customer_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customer_name"
                        v-model="customers.full_name"
                        :rules="nameRules"
                        >{{ customers.full_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showCom">
                    <label>{{$parent.lang.company_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.company_name"
                        v-model="customers.company_name"
                        >{{ customers.company_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showCom">
                    <label>{{$parent.lang.company_vatid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.company_vatid"
                        v-model="customers.company_vatid"
                        type="email"
                        >{{ customers.company_vatid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{$parent.lang.building_no}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.building_no"
                        v-model="customers.building_no"
                        type="email"
                        >{{ customers.building_no }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="showCom">
                    <label>{{$parent.lang.street_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.street_name"
                        v-model="customers.street_name"
                        type="email"
                        >{{ customers.street_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{$parent.lang.branch_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.branch_number"
                        v-model="customers.branch_number"
                        type="email"
                        >{{ customers.branch_number }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="showCom">
                    <label>{{$parent.lang.district}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.district"
                        v-model="customers.district"
                        type="email"
                        >{{ customers.district }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{$parent.lang.zipcode}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.zipcode"
                        v-model="customers.zipcode"
                        type="email"
                        >{{ customers.zipcode }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="showCom">
                    <label>{{$parent.lang.city}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.city"
                        v-model="customers.city"
                        type="email"
                        >{{ customers.city }}</b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showCom">
                    <label>{{$parent.lang.address}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.address"
                        v-model="customers.address"
                        type="email"
                        >{{ customers.address }}</b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showGov">
                    <label>{{$parent.lang.entity_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.entity_name"
                        v-model="customers.entity_name"
                        type="email"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showGov">
                    <label>{{$parent.lang.branch_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.branch_name"
                        v-model="customers.branch_name"
                        type="email"
                        ></b-form-input>
                </v-col>
            </v-row>
        </div>
        <template #modal-footer="{ close }" >
                <div class="w-100" style="direction:rtl;text-align:center;">
                    <b-button type="button" @click='addCustomer()' style="font-size:1.1rem;border:2px solid #fff !important;background:green !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.save }}</b-button>
                    <b-button type="button" @click='close()' style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.close }}</b-button>
                </div>
            </template>
        </b-modal>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default{
    data: () => ({
        customers:{
            mobile: '',
            mobile1: '',
            full_name: '',
            company_name: '',
            company_vatid: '',
            building_no: '',
            street_name: '',
            branch_number: '',
            district: '',
            zipcode: '',
            city:'',
            address:'',
            customer_type: 1,
            entity_name: '',
            branch_name: '',
        },
        showCom: false,
        showGov: false,
    }),
    computed: {
        mobileRules: function() {
            return [
                v => !!v || this.$parent.lang.required_field,
                v => (v && v.length <= 10) || this.$parent.lang.mobil_10_digits,
            ]
        },
        nameRules: function() {
            return [
                v => !!v || this.$parent.lang.required_field,
            ]
        }
    }, 
    methods: {
        changeCusType(id){
            if(id == 0){
                this.customers.customer_type = 1;
                // this.showcamp = false;
                this.showCom = false;
                this.showGov = false;
                document.getElementById('indiv').classList.remove('hideme');
                document.getElementById('comps').classList.add('hideme');
                document.getElementById('goves').classList.add('hideme');

                document.getElementById('cInd').classList.remove('redColor');
                document.getElementById('cCom').classList.remove('redColor');
                document.getElementById('cGov').classList.remove('redColor');

                document.getElementById('cInd').classList.add('redColor');
                document.getElementById('cCom').classList.add('weightColor');
                document.getElementById('cGov').classList.add('weightColor');

            }else if(id == 1){
                this.customers.customer_type = 2;
                // this.showcamp = true;
                this.showCom = true;
                this.showGov = false;
                document.getElementById('comps').classList.remove('hideme');
                document.getElementById('indiv').classList.add('hideme');
                document.getElementById('goves').classList.add('hideme');

                document.getElementById('cInd').classList.remove('redColor');
                document.getElementById('cCom').classList.remove('redColor');
                document.getElementById('cGov').classList.remove('redColor');

                document.getElementById('cInd').classList.add('weightColor');
                document.getElementById('cCom').classList.add('redColor');
                document.getElementById('cGov').classList.add('weightColor');

            }
            else if(id == 2){
                this.customers.customer_type = 3;
                // this.showcamp = true;
                this.showCom = false;
                this.showGov = true;
                document.getElementById('goves').classList.remove('hideme');
                document.getElementById('comps').classList.add('hideme');
                document.getElementById('indiv').classList.add('hideme');

                document.getElementById('cInd').classList.remove('redColor');
                document.getElementById('cCom').classList.remove('redColor');
                document.getElementById('cGov').classList.remove('redColor');

                document.getElementById('cInd').classList.add('weightColor');
                document.getElementById('cCom').classList.add('weightColor');
                document.getElementById('cGov').classList.add('redColor');

            }
            // this.changeCtype();
        },
        addCustomer(){
            // console.log(this.customers.mobile);
            let vald = true;
            let message = "";

            if(this.customers.mobile == ''){
                vald = false;
                message = this.$parent.lang.customer_info_required;
            }
            else if(this.customers.full_name == '' && vald == true){
                vald = false;
                message = this.$parent.lang.customer_info_required;
            }
            else if((this.customers.customer_type == 2 || this.customers.customer_type == 3) && vald == true){
                if(this.customers.company_name == '' || this.customers.address == ''){
                    vald = false;
                    message = this.$parent.lang.customer_company_info_required;
                }
            }

            if(vald == false){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type" , "addCustomer");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[mobile]",this.customers.mobile)
            post.append("data[mobile1]",this.customers.mobile1)
            post.append("data[full_name]",this.customers.full_name)
            post.append("data[company_name]",this.customers.company_name);
            post.append("data[company_vatid]",this.customers.company_vatid);
            post.append("data[building_no]",this.customers.building_no);
            post.append("data[street_name]",this.customers.street_name);
            post.append("data[branch_number]",this.customers.branch_number);
            post.append("data[district]",this.customers.district);
            post.append("data[zipcode]",this.customers.zipcode);
            post.append("data[city]",this.customers.city);
            post.append("data[address]",this.customers.address);
            post.append("data[customer_type]",this.customers.customer_type);
            post.append("data[entity_name]",this.customers.entity_name);
            post.append("data[branch_name]",this.customers.branch_name);
            axios.post(
                this.$SAMCOTEC.r_path , post
            ).then((response) => {
                var res = response.data;
                if(res.error.number == 200){
                    if (this.$snotify.notifications.length > 0) {
                        this.$snotify.notifications.forEach(notification => {
                            this.$snotify.remove(notification.id)
                        });
                    }
                    message = this.$parent.lang.customer_addedd;
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    
                    this.customers = {
                        mobile: '',
                        mobile1: '',
                        full_name: '',
                        company_name: '',
                        company_vatid: ''
                    }
                    this.$parent.getClients();
                    document.getElementById('hidaddcus').click();
                }else{
                    message = res.error.message;
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    
                    if(res.error.number == 202){
                        this.$parent.customer.mobile = this.customers.mobile;
                        this.$parent.customer.full_name = this.customers.full_name;
                        this.customers = {
                            mobile: '',
                            mobile1: '',
                            full_name: '',
                            email: ''
                        }
                        document.getElementById("add_customer").style.display = "none"
                    }
                }
            })
        }
    },
}
</script>